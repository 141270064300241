/*
 * @Description: 
 * @Author: weig
 * @Date: 2022-02-22 10:47:33
 * @LastEditors: weig
 * @LastEditTime: 2022-02-22 10:48:44
 */
import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

//获取系统冻结资金列表
export const GetAppFrozenBalancePage=(params)=>{
    return request.apiGet(`/api${API_VERSION}/Fund/SysFund/GetAppFrozenBalancePage`,params)
}
//获取系统金额，冻结资金
export const System=()=>{
  return request.apiGet(`/api${API_VERSION}/Fund/SysFund/GetBalance`)
}