<!--
 * @Description: 
 * @Author: 优
 * @Date: 2021-08-31 15:47:11
 * @LastEditors: weig
 * @LastEditTime: 2022-03-04 09:55:57
-->
<template>
  <div>
    <!-- 面包屑 begin -->
    <eup-crumbs icon="Money" firstCrumbs="资金管理" secondCrumbs="系统冻结" />
    <!-- 面包屑 end -->
    <!-- 内容区域 begin -->
    <div class="container">
      <el-row>
        <el-col :span="12" class="el-col-border">
          <div class="fund-content">
            <p>
              余额 <samp class="fund-text">{{maging( state.price.balance) }}</samp>
            </p>
          </div>
        </el-col>
        <el-col :span="12" class="el-col-border">
          <div class="fund-content">
            <p>
              冻结 <samp class="fund-text">{{ maging(state.price.frozenBalance) }}</samp>
            </p>
          </div>
        </el-col>
      </el-row>
      <!-- <div class="handle-box mt1r"> -->
        <el-form v-if="checkPermission([`api${state.VIEW_VERSION}:fund:sysfund:getappfrozenbalancepage`])" :inline="true" >
          <el-form-item label="商户单号">
            <el-input
            v-model="state.outTradeNo"
            placeholder="商户单号"
            
          ></el-input>
          </el-form-item>
          <el-form-item label="时间选择">
              <el-date-picker
            v-model="state.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          </el-form-item>
            <el-form-item>
          <el-button type="primary" icon="Search" @click="handleSearch">搜索</el-button>
          </el-form-item>
        </el-form>
      <!-- </div> -->
      <el-table
        :data="state.tableData"
        border
        highlight-current-row
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
        v-loading="state.loading"
      >
        <el-table-column
          prop="num"
          label="序号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="fundTypeDescribe"
          label="订单类型"
          min-width="160"
          align="center"
        ></el-table-column>
        <el-table-column prop="changeAmount" min-width="130" label="变动金额" align="center">
          <template #default="{row}">
            <el-tag
              :type="
                row.changeAmount < 0
                  ? 'success'
                  : row.changeAmount > 0
                  ? 'danger'
                  : ''
              "
              disable-transitions
              >{{
                row.changeAmount > 0
                  ? "+" + maging(row.changeAmount)
                  : row.changeAmount < 0
                  ? "-" + maging(row.changeAmount)
                  : "0"
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="outTradeNo"
          label="商户单号"
          min-width="180"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="recordDescription"
          label="订单描述"
          min-width="400"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="recordDate"
          label="创建时间"
          min-width="160"
          align="center"
           fixed="right"
        ></el-table-column>
      </el-table>
      <!-- 分页 begin -->
      <EupPagination
        :current-page="state.pageIndex"
        :pagesizes="[10, 20, 50, 100]"
        :pagesize="state.pageSize"
        :total="state.pageTotal"
        @getPageData="datasource"
        @resPageData="resPageData"
      >
      </EupPagination>
      <!-- 分页 end -->
    </div>
    <!-- 内容区域 end -->
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
import EupPagination from "../../../components/EupPagination.vue";
import { GetAppFrozenBalancePage, System } from "@/serviceApi/fund/frozen";
import { elConfirmDialog, formatDate,returnFloat} from "@/common/js/comm";
import { ElMessage, ElMessageBox } from "element-plus";
import EupCrumbs from "../../../components/eup-crumbs/index.vue"
import EnumConfig from "@/enum/EnumConfig"

var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;

export default {
  name: "",
  components: {
    EupPagination: EupPagination,
    EupCrumbs: EupCrumbs
  },
  setup() {
    const state = reactive({
      VIEW_VERSION: VIEW_VERSION,
      tableData: [],
      loading: false,
      pageIndex: 1,
      pageSize: 10,
      time: "",
      outTradeNo: "",
      multipleSelection: [],
      pageTotal: 0,
     price: {balance:0,frozenBalance:0},
    });
    onMounted(() => {
      datasource();
      System().then((res) => {
        if (res.code == 1) {
          state.price = res.data;
        } else {
          ElMessage.error(res.msg);
        }
      });
    });
    const datasource = () => {
      state.loading = true;
      var sttime = "";
      var entime = "";
      if (state.time == null) {
      } else {
        sttime = formatDate(state.time[0]);
        entime = formatDate(state.time[1]);
      }

      if (sttime == "NaN-NaN-NaN NaN:NaN:NaN") {
        sttime = "";
      }
      if (entime == "NaN-NaN-NaN NaN:NaN:NaN") {
        entime = "";
      }
      var pamre = {
        currentPage: state.currentPage,
        pageSize: state.pageSize,
          "filter.outTradeNo": state.outTradeNo,
          "filter.startDate": sttime,
          "filter.endDate": entime,
      };
      GetAppFrozenBalancePage(pamre).then((res) => {
        if (res.code == 1) {
          state.tableData = res.data.data.list;
          state.pageTotal = res.data.data.total;
          //添加num序号字段
          state.tableData.forEach((data, i) => {
            data.num = i + 1;
          });
          state.loading = false;
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const handleSelectionChange = (val) => {
      state.multipleSelection = val.map((s) => {
        return s.couponId;
      });
    };
    const resPageData = (obj) => {
      state.pageIndex = obj.currPage;
      state.pageSize = obj.pageSize;
    };
    const handleSearch = () => {
      state.pageIndex = 1;
      datasource();
    };
    const hasValidFormaStatus = (row) => {
      if (row.changeAmount > 0) {
        return "+" + row.changeAmount;
      } else {
        return "-" + row.changeAmount;
      }
    };
     const  maging=(val)=>{
        return returnFloat(val)
    }
    return {
      state,
      handleSelectionChange,
      datasource,
      maging,
      resPageData,
      handleSearch,
      hasValidFormaStatus,
    };
  },
};
</script>
<style scoped>
</style>
